/** serviceの画像ソース */
import elumildSrc from "../../../../images/top/service/logo/tmp/tmp_elumild.png";
import evandSrc from "../../../../images/top/service/logo/tmp/tmp_evand.png";
import asFineSrc from "../../../../images/top/service/logo/tmp/tmp_asfine.png";
import suprieveSrc from "../../../../images/top/service/logo/SuprieveConsulting_logo_BL.png";
import regenJapanSrc from "../../../../images/top/service/logo/tmp/tmp_regen_japan.png";
import artillSrc from "../../../../images/top/service/logo/tmp/tmp_artill.png";
import globalFrontInvestmentSrc from "../../../../images/top/service/logo/global_front_investment.png";
import { SERVICES_KEY } from "./serviceModal";

const serviceUpperList = [
  {
    key: SERVICES_KEY.ELMILD,
    src: elumildSrc,
    alt: "elumildのロゴ",
    href: "https://elumild.com/",
    text: "EC通販",
  },
  {
    key: SERVICES_KEY.EVAND,
    src: evandSrc,
    alt: "Evandのロゴ",
    href: "https://evand.jp/",
    text: "人材",
  },
  {
    key: SERVICES_KEY.AS_FINE,
    src: asFineSrc,
    alt: "As Fineのロゴ",
    href: "https://asfine.co.jp/",
    text: "福祉",
  },
  {
    key: SERVICES_KEY.SUPRIEVE_CONSULTING,
    src: suprieveSrc,
    alt: "Suprieve Consultingのロゴ",
    href: "https://suprieve-consulting.com/",
    text: "人事コンサル",
  },
];

const serviceLowerList = [
  {
    key: SERVICES_KEY.REGEN_JAPAN,
    src: regenJapanSrc,
    alt: "Regen Japanのロゴ",
    href: "https://regenjapan.com/",
    text: "美容医療",
  },
  {
    key: SERVICES_KEY.ARTILL,
    src: artillSrc,
    alt: "Artillのロゴ",
    href: "https://artill.jp/",
    text: "アート",
  },
  {
    key: SERVICES_KEY.GFI,
    src: globalFrontInvestmentSrc,
    alt: "GLOBAL FRONT INVESTMENTのロゴ",
    href: "#",
    text: "金融",
  },
];

export { serviceUpperList, serviceLowerList };
