import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import * as styles from "./VideoComponent.module.scss";
import { md } from "../core/media-queries";

export const VideoComponent = ({
  autoPlay = false,
  resize = true,
  src,
  otherSrc = [],
  onEnded = () => {},
  videoRef,
  className,
  ...rest
}) => {
  const previousSrc = useRef(src);
  const [videoTransform, setVideoTransform] = useState("");

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (previousSrc !== src) {
      videoRef.current?.load();
    }
    previousSrc.current = src;
    window.addEventListener("resize", onResize);
    requestAnimationFrame(onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = useCallback(() => {
    if (!resize) return;
    const w = window.innerWidth;
    const h = window.innerHeight;
    const newSp = window.innerWidth < md;
    const vw = newSp ? 1080 : 1920;
    const vh = newSp ? 1920 : 1080;
    const scale = Math.max(w / vw, h / vh) * 1.004;
    const [tx, ty] = [-(vw * scale - w) / 2, -(vh * scale - h) / 2];
    setVideoTransform(
      `translate3d(${tx}px, ${ty}px, 0) scale3d(${scale}, ${scale}, 1)`
    );
  }, []);

  const generateMimeType = (src: string): string => {
    const extend = src.split(".").pop().split("?").shift();
    return `video/${extend}`;
  };

  const srcType: string = useMemo((): string => generateMimeType(src), [src]);

  interface ISource {
    src: string;
    type: string;
  }
  const otherSource: Array<ISource> = useMemo(
    (): Array<ISource> =>
      otherSrc.map(
        (s: string): ISource => ({ src: s, type: generateMimeType(s) })
      ),
    [otherSrc]
  );

  return (
    <video
      className={`${styles.video} ${className || ""}`}
      muted={true}
      loop={true}
      autoPlay={autoPlay}
      ref={videoRef}
      onEnded={onEnded}
      style={{ transform: videoTransform }}
      key={src}
      {...rest}
    >
      <source src={src} type={srcType} />
      {otherSource.map((source: ISource) => (
        <source src={source.src} key={source.src} type={source.type} />
      ))}
      <p>
        Your browser doesn't support HTML5 video. Here is a
        <a href={src}>link to the video</a>
        instead.
      </p>
    </video>
  );
};
