/** companyの画像ソース */
import overviewSrc from "../../../../images/top/company/overview.png";
import cultureSrc from "../../../../images/top/company/culture.jpg";
import visionSrc from "../../../../images/top/company/vision.jpg";
import ceoMessageSrc from "../../../../images/top/company/ceoMessage.jpg";
import boardMemberSrc from "../../../../images/top/company/board-member.jpg";
import historySrc from "../../../../images/top/company/history.jpg";

const companyList = [
  {
    src: overviewSrc,
    alt: "overviewの画像",
    to: "/company/overview",
    text: "Company Overview",
    subText: "会社概要",
  },
  {
    src: cultureSrc,
    alt: "cultureの画像",
    to: "/company/culture",
    text: "Culture",
    subText: "Suprieveの文化",
  },
  {
    src: visionSrc,
    alt: "visionの画像",
    to: "/company/vision",
    text: "Vision",
    subText: "ビジョン",
  },
  {
    src: ceoMessageSrc,
    alt: "overviewの画像",
    to: "/company/ceo-message",
    text: "CEO Message",
    subText: "代表メッセージ",
  },
  {
    src: boardMemberSrc,
    alt: "boardMemberの画像",
    to: "/company/board-member",
    text: "Board Member",
    subText: "役員紹介",
  },
  {
    src: historySrc,
    alt: "historyの画像",
    to: "/company/history",
    text: "History",
    subText: "Suprieveの歴史",
  },
];

export default companyList;
