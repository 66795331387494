import React, { useEffect, useState } from "react";
import { TopPc } from "../organisms/home/pc";
import { TopSp } from "../organisms/home/sp";
import { SEO } from "../atoms/seo/SEO";
import seoImage from "../images/vision/vision-osaka.jpg";
import { Helmet } from "react-helmet";

export default () => {
  const [isPc, setIsPc] = useState(false);
  const THLESHOLD_WIDTH_SP = 992;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const windowWidth: number = window.innerWidth;
      if (windowWidth > THLESHOLD_WIDTH_SP) {
        setIsPc(true);
      }
    }

    window.addEventListener("resize", () => {
      const windowWidth: number = window.innerWidth;
      if (windowWidth > THLESHOLD_WIDTH_SP) {
        setIsPc(true);
      } else {
        setIsPc(false);
      }
    });
  }, []);

  return (
    <>
      <SEO
        pathname={"/"}
        title="Suprieve Holdings株式会社 | トップページ"
        description="スプリーブホールディングス株式会社の会社情報ページです。会社概要、事業内容、代表メッセージ、役員紹介、カルチャー、歴史、採用情報などをご覧いただけます。"
        image={seoImage}
      />
      <Helmet>
        <body data-top="true" />
      </Helmet>

      {isPc ? (
        <div>
          <TopPc />
        </div>
      ) : (
        <div>
          <TopSp />
        </div>
      )}
    </>
  );
};
