import React, { FunctionComponent, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { DefaultLayout } from "../../DefaultLayout";
import * as styles from "../pc/index.module.scss";
import { Link } from "gatsby";
import { NewsComponents } from "../../news/NewsComponents";
import { Logo } from "../../../atoms/graphics/Logo";
import { VideoComponent } from "../../../atoms/video/VideoComponent";
import FadeIn from "../../../atoms/fadein/FadeIn";
import { SERV_HOST } from "../../../atoms/constans";
import {
  ServiceModalContentProps,
  serviceModalData,
  SERVICES_KEY,
} from "../data/pc/serviceModal";
import { addOverflowHiddenToBody, ServiceModal } from "../pc";
import {
  serviceFirstList,
  serviceLastList,
  serviceSecondList,
  serviceThirdList,
} from "../data/sp/service";
import companyList from "../data/sp/company";
/** images */
import companyBackgroundSrc from "../../../images/top/company/home_company_background.png";
import officeImageOneSrc from "../../../images/top/officeImageLooper/sp_loop_service1.png";
import officeImageTwoSrc from "../../../images/top/officeImageLooper/sp_loop_service2.png";
import officeImageThreeSrc from "../../../images/top/officeImageLooper/sp_loop_service3.png";

// TODO: 別ファイルに切り出し
const officeImageList = [
  {
    src: officeImageOneSrc,
    alt: "オフィスの画像1",
  },
  {
    src: officeImageTwoSrc,
    alt: "オフィスの画像2",
  },
  {
    src: officeImageThreeSrc,
    alt: "オフィスの画像3",
  },
];

export const TopSp: FunctionComponent = ({}) => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const [isWhite, setIsWhite] = useState(true);
  const [isDisplayedMainLogo, setIsDisplayedMainLogo] = useState(true);
  const [isDisplayedServiceModal, setIsDisplayedServiceModal] = useState(false);
  // 特に初期値に意味はないがundefinedを型で持たせたくないので定義
  const [currentServiceContent, setCurrentServiceContent] =
    useState<ServiceModalContentProps>(serviceModalData[SERVICES_KEY.ELMILD]);

  if (typeof window !== `undefined`) {
    const windowHeight: number = window.innerHeight;
    window.addEventListener("scroll", () => {
      if (window.scrollY > windowHeight - 60) {
        setIsWhite(false);
      } else {
        setIsWhite(true);
      }
    });
  }

  useEffect(() => {
    // 4秒でメインロゴを消す
    setTimeout(() => {
      setIsDisplayedMainLogo(false);
    }, 4000);
  }, []);

  return (
    <DefaultLayout isWhite={isWhite} className={styles.Home}>
      <section className={styles.Home__firstSection}>
        <VideoComponent
          autoPlay={true}
          resize={false}
          videoRef={videoRef}
          loop
          playsInline
          src={`${SERV_HOST}/movies/mv-sp-0.mp4`}
          className={styles.Home__topVideo}
        />
        <div
          className={`${styles.Home__mainLogo} ${
            isDisplayedMainLogo ? "" : styles.Home__mainLogoNone
          }`}
        >
          <FadeIn>
            <Logo color={"white"} className={styles.logo} />
          </FadeIn>
        </div>
      </section>

      {/* Company */}
      <section className={styles.Home__company}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>Company</p>
          <p className={styles.Home__sectionHeaderSubTitle}>Suprieveについて</p>
        </div>

        <div className={styles.Home__sectionContent}>
          <div className={styles.Home__sectionBackground}>
            <img
              className={styles.Home__sectionBackgroundBody}
              src={companyBackgroundSrc}
              alt=""
            />
          </div>

          <FadeIn>
            <>
              {/* Sp用 view */}
              <div className={styles.Home__companyListCarousel}>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  centeredSlides={true}
                  pagination={{
                    clickable: true,
                    bulletClass: `swiper-pagination-bullet`,
                  }}
                  loop={true}
                >
                  {companyList.map((companyItem, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.Home__swiperItem}>
                        <div className={styles.Home__companyListItem}>
                          <Link
                            to={companyItem.to}
                            className={styles.Home__companyListItemLink}
                          >
                            <div
                              className={
                                styles.Home__companyListItemImageWrapper
                              }
                            >
                              <img
                                className={styles.Home__companyListItemImage}
                                src={companyItem.src}
                                alt={companyItem.alt}
                              />
                            </div>
                            <div className={styles.Home__companyListItemTitle}>
                              <p className={styles.Home__companyListItemText}>
                                {companyItem.text}
                              </p>
                              <p
                                className={styles.Home__companyListItemSubText}
                              >
                                {companyItem.subText}
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {/* ipad用 view */}
              <div className={styles.Home__companyListWrapper}>
                <ul className={styles.Home__companyList}>
                  <>
                    {companyList.map((companyItem, index) => (
                      <li key={index} className={styles.Home__companyListItem}>
                        <Link
                          to={companyItem.to}
                          className={styles.Home__companyListItemLink}
                        >
                          <div
                            className={styles.Home__companyListItemImageWrapper}
                          >
                            <img
                              className={styles.Home__companyListItemImage}
                              src={companyItem.src}
                              alt={companyItem.alt}
                            />
                          </div>
                          <div className={styles.Home__companyListItemTitle}>
                            <p className={styles.Home__companyListItemText}>
                              {companyItem.text}
                            </p>
                            <p className={styles.Home__companyListItemSubText}>
                              {companyItem.subText}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                    <li className={styles.Home__companyListItem} />
                  </>
                </ul>
              </div>
            </>
          </FadeIn>
        </div>
      </section>

      {/* Service */}
      <section className={styles.Home__service}>
        <div className={styles.Home__serviceHeader}>
          <p className={styles.Home__serviceHeaderTitle}>Service</p>
          <p className={styles.Home__serviceHeaderSubTitle}>グループ企業一覧</p>
        </div>
        <div className={styles.Home__serviceContent}>
          <FadeIn>
            <>
              <ul className={styles.Home__serviceListSp}>
                {serviceFirstList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.Home__serviceListSp}>
                {serviceSecondList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.Home__serviceListSp}>
                {serviceThirdList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.Home__serviceLastListSp}>
                {serviceLastList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
            </>
          </FadeIn>
        </div>
        <ServiceModal
          isDisplayed={isDisplayedServiceModal}
          content={currentServiceContent}
          closeModal={() => {
            setIsDisplayedServiceModal(false);
          }}
        />
      </section>

      <div className={styles.Home__officeImageCarousel}>
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={4000}
          freeMode={true}
          loop={true}
        >
          {officeImageList.map((officeImage, index) => (
            <SwiperSlide key={index}>
              <img
                className={styles.Home__officeImageBody}
                src={officeImage.src}
                alt={officeImage.alt}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* News */}
      <section className={styles.Home__news}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>News</p>
          <p className={styles.Home__sectionHeaderSubTitle}>お知らせ</p>
        </div>
        <div className={styles.Home__newsContent}>
          <NewsComponents limit={3} />
        </div>
        <div className={styles.Home__newsContent_ipad}>
          <NewsComponents limit={4} />
        </div>
        <div className={styles.Home__newsLower}>
          <a
            href="https://suprieve.com/blog"
            className={styles.Home__newsButton}
          >
            View All
          </a>
        </div>
      </section>

      {/* careers */}
      <section className={styles.Home__careers}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>Careers</p>
          <p className={styles.Home__sectionHeaderSubTitle}>採用情報</p>
        </div>
        <ul className={styles.Home__careersList}>
          <FadeIn>
            <>
              <li className={styles.Home__careersItem}>
                <Link to="/careers/new">
                  <p className={styles.Home__careersItemCategory}>
                    New graduate
                  </p>
                  <p className={styles.Home__careersItemTitle}>新卒採用</p>
                </Link>
              </li>
              <li className={styles.Home__careersItem}>
                <a href="https://evand.jp/recruit-top/">
                  <p className={styles.Home__careersItemCategory}>
                    Mid-career recruitment
                  </p>
                  <p className={styles.Home__careersItemTitle}>中途採用</p>
                  <p
                    className={`${styles.Home__careersItemTitleExplain} ${styles.Home__careersItemTitleExplain__small}`}
                  >
                    グループ企業の採用サイトに移動します
                  </p>
                </a>
              </li>
              <li
                className={`${styles.Home__careersItem} ${styles.Home__careersItem__prepared}`}
              >
                <p className={styles.Home__careersItemCategory}>
                  Mid-career recruitment
                </p>
                <p className={styles.Home__careersItemTitle}>キャリア採用</p>
                <p className={styles.Home__careersItemTitleExplain}>
                  総合・専門職
                </p>
              </li>
              <li className={styles.Home__careersItem}>
                <Link to="/careers/diversity">
                  <p className={styles.Home__careersItemCategory}>
                    Hire persons with disabilities
                  </p>
                  <p className={styles.Home__careersItemTitle}>障がい者採用</p>
                </Link>
              </li>
            </>
          </FadeIn>
        </ul>
      </section>
    </DefaultLayout>
  );
};

export const TopSPhone = () => {
  return <TopSp />;
};
