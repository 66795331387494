import React from "react";

export const Logo = ({ className, color = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.62 130.83"
    className={className}
    fill={color}
  >
    <g>
      <g>
        <rect
          x="112.02"
          y="100.41"
          width="3.25"
          height="23.47"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M31.89,113.62H22.16v10.25H18.72V100.41h3.44v10.06h9.73V100.41h3.44v23.46H31.89Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M64.66,115a19.92,19.92,0,0,1,.42-4.6A6,6,0,0,1,66.42,108a6.12,6.12,0,0,1,1.87-1.48,6.71,6.71,0,0,1,3-.66,6.87,6.87,0,0,1,3.11.66,5.71,5.71,0,0,1,1.8,1.5,5.22,5.22,0,0,1,1.37,2.33A21.24,21.24,0,0,1,78,115a20.87,20.87,0,0,1-.39,4.56,6.52,6.52,0,0,1-2.13,3.12,3.42,3.42,0,0,1-1,.69,6.42,6.42,0,0,1-3.1.73,6.71,6.71,0,0,1-4.91-2.14,6.57,6.57,0,0,1-1.34-2.4A19.42,19.42,0,0,1,64.66,115Zm10.09,0a24.88,24.88,0,0,0-.15-3.15,3.23,3.23,0,0,0-.64-1.39,2.42,2.42,0,0,0-1.07-.88,3.38,3.38,0,0,0-1.56-.34,3.43,3.43,0,0,0-1.53.34,2.87,2.87,0,0,0-1.09.88,3.73,3.73,0,0,0-.61,1.39,19.06,19.06,0,0,0-.19,3.12,18.65,18.65,0,0,0,.19,3.09,4.1,4.1,0,0,0,.61,1.45,3.82,3.82,0,0,0,1.09.85,3.32,3.32,0,0,0,1.53.36,3.27,3.27,0,0,0,1.56-.36,3,3,0,0,0,1.07-.85,3.53,3.53,0,0,0,.64-1.45A23.72,23.72,0,0,0,74.75,115Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M161.18,122.13a5.47,5.47,0,0,1-4.19,1.94,5.41,5.41,0,0,1-2.51-.63,5.1,5.1,0,0,1-1.82-1.55,5.51,5.51,0,0,1-.93-2.34,36.09,36.09,0,0,1-.22-4.41,26.5,26.5,0,0,1,.22-4.42,5.29,5.29,0,0,1,1.05-2.41,5.18,5.18,0,0,1,4.21-2.06,6.09,6.09,0,0,1,2.34.44,4.07,4.07,0,0,1,1.85,1.53v-7.81h3.25v23.46h-3.25ZM158,109.59a3.27,3.27,0,0,0-1.76.4,2.57,2.57,0,0,0-1,1.2,6.22,6.22,0,0,0-.39,1.65c-.06.63-.1,1.64-.1,2.31s0,1.61.1,2.21a6.14,6.14,0,0,0,.39,1.71,2.73,2.73,0,0,0,1,1.2,2.93,2.93,0,0,0,1.76.45,3.1,3.1,0,0,0,1.83-.48,2.56,2.56,0,0,0,1-1.21,6.4,6.4,0,0,0,.34-1.72c0-.61,0-1.57,0-2.16s0-1.62,0-2.22a5.91,5.91,0,0,0-.34-1.61,2.57,2.57,0,0,0-1-1.26A3.18,3.18,0,0,0,158,109.59Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M204.21,100h0a2.11,2.11,0,0,1,2.13,2.07v.12a2.11,2.11,0,0,1-2.13,2.07h0a2.11,2.11,0,0,1-2.13-2.07V102A2.11,2.11,0,0,1,204.21,100Z"
          shapeRendering="geometricPrecision"
        />
        <rect
          x="202.59"
          y="107.45"
          width="3.25"
          height="16.42"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M241.14,106.45h3.24v1.78h.07a5.15,5.15,0,0,1,4.2-2,5.43,5.43,0,0,1,3.68,1.46,5.77,5.77,0,0,1,1.73,4.35v11.81h-3.25V113.2a3.69,3.69,0,0,0-.85-2.69,3.46,3.46,0,0,0-4.69,0,3.7,3.7,0,0,0-.89,2.7v10.67h-3.24Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M295.07,106.45v1.77a4.09,4.09,0,0,0-1.84-1.53,6.14,6.14,0,0,0-2.35-.44,5.18,5.18,0,0,0-4.21,2.06,5.4,5.4,0,0,0-1.05,2.41,26.5,26.5,0,0,0-.22,4.42,36.09,36.09,0,0,0,.22,4.41,5.51,5.51,0,0,0,.93,2.34,5.19,5.19,0,0,0,1.82,1.55,5.41,5.41,0,0,0,2.51.63,5.47,5.47,0,0,0,4.19-1.94v1.71a3.67,3.67,0,0,1-1,2.69,3.77,3.77,0,0,1-2.68,1,3.71,3.71,0,0,1-1.71-.5,6,6,0,0,1-1.43-1L285.69,128a7.36,7.36,0,0,0,5.61,2.8,7.18,7.18,0,0,0,4.92-1.82q2-1.77,2.1-5.54v-17Zm0,10.86a6.4,6.4,0,0,1-.34,1.72,2.5,2.5,0,0,1-1,1.21,3.1,3.1,0,0,1-1.83.48,2.93,2.93,0,0,1-1.76-.45,2.73,2.73,0,0,1-1-1.2,6.14,6.14,0,0,1-.39-1.71c-.06-.6-.09-1.58-.09-2.21s0-1.68.09-2.31a6.22,6.22,0,0,1,.39-1.65,2.57,2.57,0,0,1,1-1.2,3.27,3.27,0,0,1,1.76-.4,3.18,3.18,0,0,1,1.83.47,2.52,2.52,0,0,1,1,1.26,5.91,5.91,0,0,1,.34,1.61c0,.6,0,1.57,0,2.22S295.06,116.7,295,117.31Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M330.46,118.77a6.69,6.69,0,0,0,5.13,2.14,4.22,4.22,0,0,0,2.3-.56,1.69,1.69,0,0,0,.91-1.34,1.74,1.74,0,0,0-.4-1.55,2.63,2.63,0,0,0-1.6-.7c-.73-.09-2.18-.23-2.91-.36a6.15,6.15,0,0,1-3.33-1.43,4.92,4.92,0,0,1-1.31-3.57,4.6,4.6,0,0,1,1.76-3.87,6.85,6.85,0,0,1,4.28-1.28,12.19,12.19,0,0,1,2,.14,10.46,10.46,0,0,1,1.67.45,9.43,9.43,0,0,1,2.68,1.69l-2,2.43a10.46,10.46,0,0,0-2-1.16,6.49,6.49,0,0,0-2.38-.4,3.19,3.19,0,0,0-2.14.56,1.84,1.84,0,0,0-.64,1.45,1.88,1.88,0,0,0,.48,1.34,2.84,2.84,0,0,0,1.66.6s2.15.25,2.86.36c1.94.31,3,.94,3.73,2a5.27,5.27,0,0,1,.8,3.23,4.47,4.47,0,0,1-1.92,3.75,8.21,8.21,0,0,1-4.79,1.39,10.09,10.09,0,0,1-7.12-3Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M256,23.91a22.41,22.41,0,0,0-22.81,0A17.78,17.78,0,0,0,226,32.57,35.89,35.89,0,0,0,223.53,46,35.89,35.89,0,0,0,226,59.47a17.83,17.83,0,0,0,7.13,8.66,22,22,0,0,0,11.4,3.11c.43,0,.85,0,1.28,0a22.35,22.35,0,0,0,7.59-1.54,18.12,18.12,0,0,0,5.84-3.78,23.14,23.14,0,0,0,4.16-5.51l-9.38-4a9.22,9.22,0,0,1-2.85,3A11.15,11.15,0,0,1,244.77,61a9.4,9.4,0,0,1-5.59-2.06,11,11,0,0,1-3.35-5.43,23.62,23.62,0,0,1-.8-3.56h30.34c.12-1.29.19-2.59.19-3.89a35.89,35.89,0,0,0-2.48-13.45A17.78,17.78,0,0,0,256,23.91ZM235,41.46a20.9,20.9,0,0,1,.87-3.7,9.93,9.93,0,0,1,3.35-4.95,9,9,0,0,1,10.73,0,10,10,0,0,1,3.35,4.95,20.9,20.9,0,0,1,.87,3.7Z"
          shapeRendering="geometricPrecision"
        />
        <rect
          x="202.59"
          y="21.35"
          width="12.43"
          height="49.43"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M186.27,35.27a7.33,7.33,0,0,0-1.62-1.71,8.18,8.18,0,0,0-4.94-1.65A7.45,7.45,0,0,0,175,33.74a9.4,9.4,0,0,0-2.88,4.77,29.29,29.29,0,0,0-.82,7.51h0V70.78H159V21.35h12v4.73a13.2,13.2,0,0,1,2.37-2.17,17.49,17.49,0,0,1,19.94,0A14,14,0,0,1,195.55,26Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M83.68,46.4A34.68,34.68,0,0,1,83,53.91a8,8,0,0,1-2.74,4.66,7.71,7.71,0,0,1-9.61,0,8,8,0,0,1-2.74-4.66,34.68,34.68,0,0,1-.66-7.51v-25H54.62v25a50.12,50.12,0,0,0,1.44,13.16,15.49,15.49,0,0,0,5.7,8.67,17.54,17.54,0,0,0,19.94,0,13.94,13.94,0,0,0,2.36-2.18v4.73H96V21.35H83.68Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M142,23.91a17.49,17.49,0,0,0-19.94,0,13.2,13.2,0,0,0-2.37,2.17V21.35h-12V88.9h12.33V66.38a13.78,13.78,0,0,0,2,1.75,17.54,17.54,0,0,0,19.94,0,15.46,15.46,0,0,0,5.7-8.66A51,51,0,0,0,149.15,46a51,51,0,0,0-1.44-13.45A15.43,15.43,0,0,0,142,23.91Zm-6.17,29.91a8.06,8.06,0,0,1-2.71,4.66,7.59,7.59,0,0,1-9.5,0,8,8,0,0,1-2.7-4.66,35.47,35.47,0,0,1-.66-7.8,35.41,35.41,0,0,1,.66-7.8,8,8,0,0,1,2.7-4.66,7.56,7.56,0,0,1,9.5,0,8,8,0,0,1,2.71,4.66,36,36,0,0,1,.66,7.8A36.09,36.09,0,0,1,135.84,53.82Z"
          shapeRendering="geometricPrecision"
        />
        <polygon
          points="291.3 51.9 281.25 21.35 268.25 21.35 286.46 70.78 296.14 70.78 314.26 21.35 301.26 21.35 291.3 51.9"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M358.62,46a35.89,35.89,0,0,0-2.48-13.45A17.8,17.8,0,0,0,349,23.91a22.39,22.39,0,0,0-22.8,0,17.78,17.78,0,0,0-7.13,8.66A35.69,35.69,0,0,0,316.59,46a35.69,35.69,0,0,0,2.48,13.45,17.83,17.83,0,0,0,7.13,8.66,22,22,0,0,0,11.4,3.11c.43,0,.85,0,1.27,0a22.36,22.36,0,0,0,7.6-1.54,18,18,0,0,0,5.83-3.78,23.18,23.18,0,0,0,4.17-5.51l-9.38-4a9.33,9.33,0,0,1-2.85,3A11.17,11.17,0,0,1,337.83,61a9.38,9.38,0,0,1-5.59-2.06,11,11,0,0,1-3.35-5.43,24.88,24.88,0,0,1-.81-3.56h30.35C358.55,48.62,358.62,47.32,358.62,46ZM328,41.46a21.8,21.8,0,0,1,.88-3.7,9.93,9.93,0,0,1,3.35-4.95,9,9,0,0,1,10.72,0,10,10,0,0,1,3.36,4.95,21.81,21.81,0,0,1,.87,3.7Z"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M29.32,32.3c-4.25-.93-12.44-.55-14.9-6.35a8,8,0,0,1,1.94-9.29,10,10,0,0,1,6.72-2.34c6.82-.11,11.42,2.26,16.16,6.87,2.63-2.63,5.28-5.26,7.92-7.88-8-9-22.2-12.44-33.57-8.58a19.06,19.06,0,0,0-9,29.54c4.18,5.56,10,6.88,16.23,8.06,4.47.85,12.23,1.26,13.43,7.93.64,3.52-.48,6.45-3.43,8.32-2.49,1.57-4.27,1.64-7.06,1.59C17,60.06,12.52,57.43,7.9,52.85L0,60.72c4.27,4.71,8.74,7.9,15,9.5a36.19,36.19,0,0,0,18.54-.08A19.16,19.16,0,0,0,45,41.28C41.26,35.32,35.61,33.68,29.32,32.3Z"
          shapeRendering="geometricPrecision"
        />
        <circle
          cx="208.8"
          cy="7.34"
          r="7.34"
          shapeRendering="geometricPrecision"
        />
      </g>
    </g>
  </svg>
);
