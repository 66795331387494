/** serviceの画像ソース */
import elumildSrc from "../../../../images/top/service/modal/mainImage/elumild.png";
import elumildLogoSrc from "../../../../images/top/service/modal/logo/elumild.png";
import evandSrc from "../../../../images/top/service/modal/mainImage/evand.png";
import evandLogoSrc from "../../../../images/top/service/modal/logo/evand.png";
import asFineSrc from "../../../../images/top/service/modal/mainImage/asfine.png";
import asFineLogoSrc from "../../../../images/top/service/modal/logo/asfine.png";
import suprieveConsultingSrc from "../../../../images/top/service/modal/mainImage/suprieveConsulting.png";
import suprieveConsultingLogoSrc from "../../../../images/top/service/modal/logo/suprieveConsulting.png";
import regenJapanSrc from "../../../../images/top/service/modal/mainImage/regenJapan.png";
import regenJapanLogoSrc from "../../../../images/top/service/modal/logo/regenJapan.png";
import artillSrc from "../../../../images/top/service/modal/mainImage/artill.png";
import artillLogoSrc from "../../../../images/top/service/modal/logo/artill.png";
// import globalFrontInvestmentSrc from "../../../../images/top/service/modal/mainImage/globalFrontInvestment.png";
import globalFrontInvestmentLogoSrc from "../../../../images/top/service/modal/logo/globalFrontInvestment.png";

const SERVICES_KEY = {
  ELMILD: "elumild",
  EVAND: "evand",
  AS_FINE: "asFine",
  SUPRIEVE_CONSULTING: "suprieveConsulting",
  REGEN_JAPAN: "regenJapan",
  ARTILL: "artill",
  GFI: "gfi",
};

export type ServiceModalContentProps = {
  main: {
    // TODO: 画像はanyになってしまっているので、stringで解決したい
    src: any;
    alt: string;
  };
  src: any;
  alt: string;
  href: string;
  catchCopy: string;
  text: string;
  businessContentUpper: string;
  businessContentLower?: string;
};

type ServiceModalDataProps = {
  [key: string]: ServiceModalContentProps;
};

const serviceModalData: ServiceModalDataProps = {
  [SERVICES_KEY.ELMILD]: {
    main: {
      src: elumildSrc,
      alt: "elumildのイメージ画像",
    },
    src: elumildLogoSrc,
    alt: "elumildのロゴ",
    href: "https://elumild.com/",
    catchCopy: "商品の輝きで、世界を照らす",
    text: "さまざまなモノであふれかえる時代、本当に良い商品を選ぶのが困難になっています。私たちは、作り手だけの満足や営利主義の商品ではなく、妥協を許さず、本当に良いモノを取り扱い世界中に発信していきます。",
    businessContentUpper:
      "事業内容:製品ブランディング、 および企画業務・ セールス＆プロダクト",
    businessContentLower: "取り扱い品目：健康食品/化粧品/医薬部外品",
  },
  [SERVICES_KEY.EVAND]: {
    main: {
      src: evandSrc,
      alt: "Evandのイメージ画像",
    },
    src: evandLogoSrc,
    alt: "evandのロゴ",
    href: "https://evand.jp/",
    catchCopy: "「人」が輝く、「世界」がかわる",
    text: "仕事を通して、なりたい自分になれている。そんな社員から放たれる輝きによって、目の前のお客様、クライアント、同じ職場の人間、自分の周りの世界をプラスに変えていく。",
    businessContentUpper:
      "事業内容:製品ブランディング、 および企画業務・ セールス＆プロダクト",
    businessContentLower:
      "事業内容:通信キャリア・コールセンターを運営する、大手上場企業を中心としたクライアントの業務代行事業。",
  },
  [SERVICES_KEY.AS_FINE]: {
    main: {
      src: asFineSrc,
      alt: "As Fineのイメージ画像",
    },
    src: asFineLogoSrc,
    alt: "As Fineのロゴ",
    href: "https://asfine.co.jp/",
    catchCopy: "明日を楽しみに出来る社会を創る",
    text: "企業等に就労することが困難な障害のある方に対して、雇用契約に基づく生産活動の機会の提供、知識および能力の向上のために必要な訓練などを行います。",
    businessContentUpper:
      "事業内容:就労継続支援A型事業所の運営。PC入力業務、ECサイトの商品登録、地図のスキャン業務、ゲームのバグチェック、HP制作、プログラミング業務等。",
  },
  [SERVICES_KEY.SUPRIEVE_CONSULTING]: {
    main: {
      src: suprieveConsultingSrc,
      alt: "Suprieve Consultingのイメージ画像",
    },
    src: suprieveConsultingLogoSrc,
    alt: "Suprieve Consultingのロゴ",
    href: "https://suprieve-consulting.com/",
    catchCopy: "人事のラスト1ピース",
    text: "クライアントの人事課題全般を解決する人事企画コンサルティングサービス。貴社人事部のラスト1ピースとして、独自のフレームワークに基づき人事課題を抽出し、事業フェーズや経営目標の達成に最適な組織を実現。",
    businessContentUpper:
      "事業内容：人事戦略立案、人事制度設計支援、組織設計支援、要人件費最適化支援、業務効率化支援",
  },
  [SERVICES_KEY.REGEN_JAPAN]: {
    main: {
      src: regenJapanSrc,
      alt: "Regen Japanのイメージ画像",
    },
    src: regenJapanLogoSrc,
    alt: "Regen Japanのロゴ",
    href: "https://regenjapan.com/",
    catchCopy: "美容の⼒で⼈⽣に輝きを。",
    text: "⾒た⽬が美しくなると、⽣き⽅にも⾃信が⽣まれます。私たちは外⾒も内⾯も豊かで美しくなれる事に貢献し、多くの⼈々を明るく幸せにします。そんな願いをもって業界のリーディングカンパニーを目指します。",
    businessContentUpper:
      "事業内容：美容医療向けコスメの世界販売、クリニック運営サポート",
  },
  [SERVICES_KEY.ARTILL]: {
    main: {
      src: artillSrc,
      alt: "Artillのイメージ画像",
    },
    src: artillLogoSrc,
    alt: "Artillのロゴ",
    href: "https://artill.jp/",
    catchCopy: "アートで輝かしい平和な未来を創っていく",
    text: "アートには言葉を介さずとも、人と人をつなげる力がある、人の心を揺れ動かす力がある、私たちはそんなアートの力を誰よりも信じています。",
    businessContentUpper:
      "事業内容：アプリケーション開発、イベント企画、メディア運営",
  },
  [SERVICES_KEY.GFI]: {
    main: {
      src: "",
      alt: "Global Front Investmentのイメージ画像",
    },
    src: globalFrontInvestmentLogoSrc,
    alt: "Global Front Investmentのロゴ",
    href: "",
    catchCopy: "",
    text: "",
    businessContentUpper: "",
  },
};

export { serviceModalData, SERVICES_KEY };
