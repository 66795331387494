import React, { FunctionComponent, useEffect, useState, VFC } from "react";
import { DefaultLayout } from "../../DefaultLayout";
import * as styles from "./index.module.scss";
import { VideoComponent } from "../../../atoms/video/VideoComponent";
import { Logo } from "../../../atoms/graphics/Logo";
import { Link } from "gatsby";
import { NewsComponents } from "../../news/NewsComponents";
import Footer from "../../core/Footer";
import { SERV_HOST } from "../../../atoms/constans";
import companyBackgroundSrc from "../../../images/top/company/home_company_background.png";
import { ChevronRight } from "../../../atoms/graphics/ChevronRight";
// TODO: SVG化する
import IconCross from "../../../images/icons/cross.png";
import FadeIn from "../../../atoms/fadein/FadeIn";
// data
import companyList from "../data/pc/company";
import { serviceUpperList, serviceLowerList } from "../data/pc/service";
import {
  ServiceModalContentProps,
  serviceModalData,
  SERVICES_KEY,
} from "../data/pc/serviceModal";

export type ServiceModalProps = {
  isDisplayed?: boolean;
  content: ServiceModalContentProps;
  closeModal?(): void;
};

export function addOverflowHiddenToBody(): void {
  const body = document.querySelector("body");
  if (body) {
    body.style.overflowY = "hidden";
  }
}

function removeOverflowHiddenFromBody(): void {
  const body = document.querySelector("body");
  if (body) {
    body.style.overflowY = "visible";
  }
}

export const ServiceModal: FunctionComponent<ServiceModalProps> = ({
  isDisplayed = false,
  content,
  closeModal = () => {},
}) =>
  isDisplayed ? (
    <div className={styles.Home__serviceModal}>
      <div
        className={styles.Home__serviceModalOverlay}
        onClick={() => {
          closeModal();
          removeOverflowHiddenFromBody();
        }}
      />
      <div className={styles.Home__serviceModalContentWrapper}>
        <div className={styles.Home__serviceModalContent}>
          <div className={styles.Home__serviceModalContentImage}>
            <img
              src={content.main.src}
              alt={content.main.alt}
              className={styles.Home__serviceModalImage}
            />
          </div>
          <div className={styles.Home__serviceModalContentText}>
            <img
              src={content.src}
              alt={content.alt}
              className={styles.Home__serviceModalLogo}
            />
            <p className={styles.Home__serviceModalCatchCopy}>
              {content.catchCopy}
            </p>
            <p className={styles.Home__serviceModalText}>{content.text}</p>
            <div className={styles.Home__serviceModalBusinessTexts}>
              <p className={styles.Home__serviceModalBusinessText}>
                {content.businessContentUpper}
              </p>
              {content.businessContentLower ? (
                <p className={styles.Home__serviceModalGoodsText}>
                  {content.businessContentLower}
                </p>
              ) : null}
            </div>
            {content.catchCopy === "最前列のその先へ" ? null : (
              <div className={styles.Home__serviceModalLinkWrapper}>
                <a
                  href={content.href}
                  className={styles.Home__serviceModalLink}
                >
                  公式HP
                </a>
              </div>
            )}
          </div>
        </div>
        <button
          className={styles.Home__serviceModalCloseButton}
          onClick={() => {
            closeModal();
            removeOverflowHiddenFromBody();
          }}
        >
          <img
            src={IconCross}
            alt="バツボタン"
            className={styles.Home__serviceModalCloseButtonIcon}
          />
        </button>
      </div>
    </div>
  ) : null;

export const HomeComponent = () => {
  const [isSp, setIsSp] = useState(false);
  const videoRef = React.createRef<HTMLVideoElement>();
  const [isWhite, setIsWhite] = useState(true);
  const [isDisplayedMainLogo, setIsDisplayedMainLogo] = useState(true);
  const [isDisplayedServiceModal, setIsDisplayedServiceModal] = useState(false);
  // 特に初期値に意味はないがundefinedを型で持たせたくないので定義
  const [currentServiceContent, setCurrentServiceContent] =
    useState<ServiceModalContentProps>(serviceModalData[SERVICES_KEY.ELMILD]);

  const handlePageTop = () => {
    if (window) {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  if (typeof window !== `undefined`) {
    const windowHeight: number = window.innerHeight;
    window.addEventListener("scroll", () => {
      if (window.scrollY > windowHeight) {
        setIsWhite(false);
      } else {
        setIsWhite(true);
      }
    });
  }

  useEffect(() => {
    // 4秒でメインロゴを消す
    setTimeout(() => {
      setIsDisplayedMainLogo(false);
    }, 4000);
  }, []);

  return (
    <DefaultLayout isWhite={isWhite} className={styles.Home} footer={false}>
      {/* Top */}
      <section className={styles.Home__firstSection}>
        <FadeIn>
          <VideoComponent
            autoPlay={true}
            videoRef={videoRef}
            src={
              isSp
                ? `${SERV_HOST}/movies/mv-sp-0.mp4`
                : `${SERV_HOST}/movies/mv-pc-0.mp4`
            }
            className=""
          />
        </FadeIn>
        <div
          className={`${styles.Home__mainLogo} ${
            isDisplayedMainLogo ? "" : styles.Home__mainLogoNone
          }`}
        >
          <Logo color={"white"} className={styles.logo} />
        </div>
      </section>

      {/* Company */}
      <section className={styles.Home__company}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>Company</p>
          <p className={styles.Home__sectionHeaderSubTitle}>Suprieveについて</p>
        </div>
        <div className={styles.Home__sectionContent}>
          <div className={styles.Home__sectionBackground}>
            <img
              className={styles.Home__sectionBackgroundBody}
              src={companyBackgroundSrc}
              alt=""
            />
          </div>
          <FadeIn>
            <div className={styles.Home__companyListWrapper}>
              <ul className={styles.Home__companyList}>
                <>
                  {companyList.map((companyItem, index) => (
                    <li key={index} className={styles.Home__companyListItem}>
                      <Link
                        to={companyItem.to}
                        className={styles.Home__companyListItemLink}
                      >
                        <div
                          className={styles.Home__companyListItemImageWrapper}
                        >
                          <img
                            className={styles.Home__companyListItemImage}
                            src={companyItem.src}
                            alt={companyItem.alt}
                          />
                        </div>
                        <div className={styles.Home__companyListItemTitle}>
                          <p className={styles.Home__companyListItemText}>
                            {companyItem.text}
                          </p>
                          <p className={styles.Home__companyListItemSubText}>
                            {companyItem.subText}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                  <li className={styles.Home__companyListItem} />
                </>
              </ul>
            </div>
          </FadeIn>
        </div>
      </section>

      {/* Service */}
      <section className={styles.Home__service}>
        <div className={styles.Home__serviceHeader}>
          <p className={styles.Home__serviceHeaderTitle}>Service</p>
          <p className={styles.Home__serviceHeaderSubTitle}>グループ企業一覧</p>
        </div>
        <div className={styles.Home__serviceContent}>
          <FadeIn>
            <>
              <ul className={styles.Home__serviceUpperList}>
                {serviceUpperList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.Home__serviceLowerList}>
                {serviceLowerList.map((serviceItem, index) => (
                  <li className={styles.Home__serviceItem} key={index}>
                    <div className={styles.Home__serviceItemImage}>
                      <img
                        src={serviceItem.src}
                        alt={serviceItem.alt}
                        className={styles.Home__serviceItemImageBody}
                      />
                    </div>
                    <button
                      className={styles.Home__serviceItemLink}
                      onClick={() => {
                        setCurrentServiceContent(
                          serviceModalData[serviceItem.key]
                        );
                        setIsDisplayedServiceModal(true);
                        addOverflowHiddenToBody();
                      }}
                    >
                      {serviceItem.text}
                    </button>
                  </li>
                ))}
              </ul>
            </>
          </FadeIn>
        </div>
        <ServiceModal
          isDisplayed={isDisplayedServiceModal}
          content={currentServiceContent}
          closeModal={() => {
            setIsDisplayedServiceModal(false);
          }}
        />
      </section>

      {/* News */}
      <section className={styles.Home__news}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>News</p>
          <p className={styles.Home__sectionHeaderSubTitle}>お知らせ</p>
        </div>
        <div className={styles.Home__newsContent}>
          <NewsComponents limit={4} />
        </div>
        <div className={styles.Home__newsLower}>
          <a
            href="https://suprieve.com/blog"
            className={styles.Home__newsButton}
          >
            View All
          </a>
        </div>
      </section>

      {/* careers */}
      <section className={styles.Home__careers}>
        <div className={styles.Home__sectionHeader}>
          <p className={styles.Home__sectionHeaderTitle}>Careers</p>
          <p className={styles.Home__sectionHeaderSubTitle}>採用情報</p>
        </div>
        <div className={styles.Home__careersListWrapper}>
          <FadeIn>
            <ul className={styles.Home__careersList}>
              <li className={styles.Home__careersItem}>
                <p className={styles.Home__careersItemCategory}>New graduate</p>
                <p className={styles.Home__careersItemTitle}>新卒採用</p>
                <p className={styles.Home__careersItemTitleExplain}>総合職</p>
                <Link
                  to="/careers/new"
                  className={styles.Home__careersItemLink}
                >
                  <div className={styles.Home__arrowRightIcon}>
                    <ChevronRight size={32} />
                  </div>
                </Link>
              </li>
              <li className={styles.Home__careersItem}>
                <div className={styles.Home__careersItemCategory}>
                  <p>Mid-career</p>
                  <p>recruitment</p>
                </div>
                <p className={styles.Home__careersItemTitle}>中途採用</p>
                <p
                  className={`${styles.Home__careersItemTitleExplain} ${styles.Home__careersItemTitleExplain__small}`}
                >
                  グループ企業の採用サイトに移動します
                </p>
                <a
                  href="https://evand.jp/recruit-top/"
                  className={styles.Home__careersItemLink}
                >
                  <div className={styles.Home__arrowRightIcon}>
                    <ChevronRight size={32} />
                  </div>
                </a>
              </li>
              <li
                className={`${styles.Home__careersItem} ${styles.Home__careersItem__prepared}`}
              >
                <div className={styles.Home__careersItemCategory}>
                  <p>Mid-career</p>
                  <p>recruitment</p>
                </div>
                <p className={styles.Home__careersItemTitle}>キャリア採用</p>
                <p className={styles.Home__careersItemTitleExplain}>
                  総合・専門職
                </p>
                <a
                  className={`${styles.Home__careersItemLink} ${styles.Home__careersItemLink__disabled}`}
                >
                  <div className={styles.Home__arrowRightIcon}>
                    <ChevronRight size={32} />
                  </div>
                </a>
              </li>
              <li className={styles.Home__careersItem}>
                <div className={styles.Home__careersItemCategory}>
                  <p>Hire persons</p>
                  <p>with disabilities</p>
                </div>
                <p className={styles.Home__careersItemTitle}>障がい者採用</p>
                <p className={styles.Home__careersItemTitleExplain}></p>
                <Link
                  to="/careers/diversity"
                  className={styles.Home__careersItemLink}
                >
                  <div className={styles.Home__arrowRightIcon}>
                    <ChevronRight size={32} />
                  </div>
                </Link>
              </li>
            </ul>
          </FadeIn>
        </div>
      </section>

      <div>
        <section>
          <Footer className={styles.topFooter} onPageTop={handlePageTop} />
        </section>
        {/* </div> */}
      </div>
    </DefaultLayout>
  );
};

export const TopPc = () => {
  return <HomeComponent />;
};
