import React from "react";
import { POSTS_WITH_LIMIT } from "./news-queries";
import { useQuery } from "@apollo/react-hooks";
import { categoryClasses, WpPost } from "./NewsItem";
import * as styles from "./news.module.scss";
import { format } from "date-fns";
import { compact } from "lodash";
import FadeIn from "../../atoms/fadein/FadeIn";

function getNewsUrl(host: string): string {
  const isStg = host.includes("staging");
  const isLocal = host.includes("localhost");

  return isStg || isLocal
    ? "https://staging.suprieve.com/blog/"
    : "https://suprieve.com/blog/";
}

export const NewsComponents = ({ limit }) => {
  if (typeof window === "undefined") {
    return null;
  }

  const host = window.location.host;

  const { loading, error, data } = useQuery(POSTS_WITH_LIMIT, {
    variables: { limit },
  });
  return (
    <div className={styles.blogParts}>
      {error && <span>ニュースの読み込みに失敗しました</span>}
      {!loading &&
        !error &&
        data.posts.edges.map((node, i) => {
          const post = new WpPost(node);
          return (
            <div
              key={`blog-parts-${i}`}
              className={styles.blogPartsItemWrapper}
            >
              <FadeIn>
                <a href={`${getNewsUrl(host)}${post.slug}`}>
                  <div className={styles.blogPartsItem}>
                    <img
                      className={styles.postImg}
                      src={post.src}
                      srcSet={post.srcSet}
                      sizes={post.sizes}
                    />
                    <h2>{post.title}</h2>
                    <div className={styles.inner}>
                      <p
                        className={compact([
                          styles.frame,
                          categoryClasses[post.categoryName],
                        ]).join(" ")}
                      >
                        {post.categoryName}
                      </p>
                      <div className={styles.flex1}> </div>
                      <span className={styles.date}>
                        {format(new Date(post.date), "yyyy/MM/dd")}
                      </span>
                    </div>
                  </div>
                </a>
              </FadeIn>
            </div>
          );
        })}
    </div>
  );
};
